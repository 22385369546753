import React, { Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Loader, NavBar } from '..'
import notFound from '../../pages/notFound'
import { Faq } from '../../pages/faq'
import { Login } from '../../pages/login'
import { SignUp } from '../../pages/sign-up'
import CustomerDashboard from '../../pages/dashboard/Dashboard'
import { Loans } from '../../pages/loans'
import { Feedbacks } from '../../pages/feedbacks'
import { BvnConsentSuccessPage } from '../../pages/bvn-consent-success'
import { DirectDebitSuccessPage } from '../../pages/direct-debit-consent-success'
import { Directors } from '../../pages/directors'
import { DocumentsPage } from '../../pages/documents'
import { ContactUsPage } from '../../pages/contact-us'
import Applications from '../../pages/applications/Applications'
import { NewApplication } from '../../pages/newApplication'
import CustomerRoutes from './CustomerRoutes'
import AuthGuard from './AuthGuard'
import AdminRoute from './AdminRoutes'
import { Profile } from '../../pages/profile'
import ApplicationsDetails from '../../pages/applications/ApplicationsDetails'
import AdminDashboard from '../../pages/admin-pages/dashboard/AdminDashboard'
import AdminPendingApp from '../../pages/admin-pages/applications/AdminPendingApp'
import AdminCustomers from '../../pages/admin-pages/customers/AdminContacts'
import AdminRoles from '../../pages/admin-pages/roles/AdminRoles'
import { Reminder } from '../../pages/admin-pages/reminder'
import * as roles from '../../utils/roles'
import { Settings } from '../../pages/settings'
import SystemLogs from '../../pages/admin-pages/system-logs/SystemLogs'
import { Administration } from '../../pages/admin-pages/administration'
import Templates from '../../pages/templates/Templates'
import FormPreview from '../../pages/form-preview/FormPreview'
import ApplicationSuccess from '../../pages/application-success/ApplicationSuccess'
import AdminApprovedApp from '../../pages/admin-pages/applications/AdminApprovedApp'
import AdminDisbursed from '../../pages/admin-pages/applications/AdminDisbursed'
import { AdminDeclined } from '../../pages/admin-pages/applications'
import ConfirmSignupByLink from '../../pages/sign-up/ConfirmSignByLink'
import ScrollToTop from '../../sharedComponents/ScrollToTop'
import HtmlAgreement from '../agreementDocs/MOUdocs'
import DocusignComplete from '../../pages/docusign/DocusignComplete'
import TransactionHistory from '../../pages/admin-pages/customers/TransactionHistory'
import InterestTracker from '../../pages/admin-pages/interest-tracker/InterestTracker'
import DirectorAcceptLoan from '../../pages/directors/DirectorAcceptLoan'
import DistributorsList from '../../pages/distributors/distributors'
import UploadDoc from '../../pages/documents/DocumentsPage'
import AdminUploadDoc from '../../pages/documents/adminUploadDoc'
import CustomerDirectors from '../../pages/admin-pages/customer-directors/Customer-directors'
import ConfirmDirectorByLink from '../../pages/directors/ConfirmDirectors'
import { Notification } from '../dashboardLayout/Notification'
import Blog from '../../pages/blog/Blog'
import BlogPage from '../../pages/blog/components/BlogPage'
import FeatureFlagedRoutes from './FeatureFlagedRoutes'
import { dispatch } from '../../redux/store'
import InterestPaymentDetails from '../../pages/admin-pages/interest-tracker/InterestPaymentDetails'
import { useSelector } from 'react-redux'
import ClosedLoans from '../../pages/admin-pages/closed-loans/ClosedLoans'
import CustomerModule from '../../pages/admin-pages/customer-revamp/CustomerModuleRevamp'
import CustomerNavbar from '../../pages/admin-pages/customer-revamp/CustomerNavbars'
import NewApplyForLoan from '../../pages/admin-pages/apply-for-loan/New-apply-loan/NewApplyForLoan'
import ApplyExistingLoan from '../../pages/admin-pages/applyLoanForExistingCustomer/ApplyExistingLoan'
import ResumeLoanApplication from '../../pages/admin-pages/apply-for-loan/New-apply-loan/ResumeLoanApplication'
import AccountTransferNav from '../../pages/admin-pages/customer-revamp/account-transfer'
import CreateAccountNav from '../../pages/admin-pages/customer-revamp/create-account'
import LeadsTable from '../../pages/admin-pages/leads/LeadsTable'
import SaasCustomerTable from '../../pages/admin-pages/saas-customers/SaasCustomerTable'
import ReceiptPage from '../../pages/admin-pages/customer-revamp/create-account/ReceiptPage'
import LeadsForm from '../../pages/leads/LeadsForm'
import FinanceNav from '../../pages/admin-pages/finance'
import HealthFinanceLanding from '../../pages/health-finance/landing.jsx'
import HealthFinanceLandingSuccess from '../../pages/health-finance/success.jsx'
import CareerPage from '../../pages/career-page/index.jsx'
import CustomerDocs from '../../pages/admin-pages/customer-revamp/CustomerDocs.js'
import Messages from '../../pages/messages/index.jsx'
import RiskManagement from '../../pages/admin-pages/risk-management/index.jsx'
import ReviewDetailsPage from '../../pages/admin-pages/risk-management/review/ReviewDetailsPage.jsx'
import ApprovedDetailsPage from '../../pages/admin-pages/risk-management/approved/ApprovedDetailsPage.jsx'
import DisbursedDetailsPage from '../../pages/admin-pages/risk-management/disbursed/DisbursedDetailsPage.jsx'
import DeclinedDetailsPage from '../../pages/admin-pages/risk-management/declined/DeclinedDetailsPage.jsx'
import BlackList from '../../pages/admin-pages/blacklist/index.jsx'
const HowItWorks = React.lazy(() => import('../../pages/how-it-works/Index'))
const AboutUs = React.lazy(() => import('../../pages/AboutUsPage/AboutUs'))
const FooterNew = React.lazy(() => import('../footer/FooterNew'))
const Landing = React.lazy(() => import('../../pages/landing/Landing'))
const PrivacyPolicy = React.lazy(() => import('../../pages/privacy-policy/PrivacyPolicy'))
const Routes = () => {
  const { shouldShowNewBlog } = useSelector(({ featureToggle }) => ({
    shouldShowNewBlog: featureToggle.features?.enableNewBlog
  }))
  useEffect(() => {
    dispatch.featureToggle.getAllFeatureToggles()
  }, [])

  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Suspense fallback={Loader}>
        <Switch>
          <Route exact path='/'>
            <Landing />
          </Route>
          <Route exact path='/how-it-works'>
            <HowItWorks />
          </Route>
          <Route exact path='/about'>
            <AboutUs />
          </Route>
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/career-page' component={CareerPage} />
          <Route exact path='/faq' component={Faq} />
          {shouldShowNewBlog && <Route exact path='/blog' component={Blog} />}
          <Route exact path='/blog/:title' component={BlogPage} />
          <Route exact path='/leads' component={LeadsForm} />
          <Route exact path='/health-finance' component={HealthFinanceLanding} />
          <Route exact path='/health-finance-success' component={HealthFinanceLandingSuccess} />
          <Route exact path='/feedbacks' component={Feedbacks} />
          <Route exact path='/bvn-consent-success' component={BvnConsentSuccessPage} />
          <Route exact path='/direct-debit-consent-success' component={DirectDebitSuccessPage} />
          <Route exact path='/directors' component={Directors} />
          <Route exact path='/documents' component={DocumentsPage} />
          <Route exact path='/upload-docs' component={UploadDoc} />
          <Route exact path='/contact-us' component={ContactUsPage} />
          <Route exact path='/confirm-signup' component={ConfirmSignupByLink} />
          <Route exact path='/confirm-director' component={ConfirmDirectorByLink} />
          <Route exact path='/trans-history' component={TransactionHistory} />
          <Route exact path='/directors-accept-loan' component={DirectorAcceptLoan} />

          <AuthGuard exact path='/login' component={Login} />
          <AuthGuard exact path='/sign-up' component={SignUp} />

          <CustomerRoutes exact path='/dashboard' component={CustomerDashboard} />
          <CustomerRoutes exact path='/form-preview' component={FormPreview} />
          <CustomerRoutes exact path='/new-application' component={NewApplication} />
          <CustomerRoutes exact path='/application-success' component={ApplicationSuccess} />
          <CustomerRoutes exact path='/applications/:slug' component={Applications} />
          <CustomerRoutes exact path='/application/:slug' component={ApplicationsDetails} />
          <CustomerRoutes exact path='/profile' component={Profile} />
          <CustomerRoutes exact path='/transactions' component={Loans} />
          <CustomerRoutes exact path='/sign-complete' component={DocusignComplete} />

          <FeatureFlagedRoutes exact path='/blog' component={Blog} />
          <FeatureFlagedRoutes
            privileges={[roles.VIEW_LOAN_APPLICATIONS]}
            exact
            path='/notifications'
            component={Notification}
          />
          <FeatureFlagedRoutes
            privileges={[roles.VIEW_LOAN_APPLICATIONS]}
            exact
            path='/customer-notifications'
            component={Notification}
          />

          <AdminRoute
            exact
            path='/templates'
            privileges={[roles.VIEW_TEMPLATES]}
            component={Templates}
          />
          <AdminRoute
            exact
            path='/loan-in-progress/:loanId'
            privileges={[roles.VIEW_LOAN_APPLICATIONS]}
            component={ResumeLoanApplication}
          />
          <AdminRoute
            exact
            path='/admin-dashboard'
            privileges={[roles.VIEW_DASHBOARD]}
            component={AdminDashboard}
          />
          <AdminRoute
            exact
            path='/admin-pending'
            privileges={[roles.VIEW_LOAN_APPLICATIONS]}
            component={AdminPendingApp}
          />
          <AdminRoute
            exact
            path='/admin-approved'
            privileges={[roles.APPROVE_REJECTED_LOANS]}
            component={AdminApprovedApp}
          />
          <AdminRoute
            exact
            path='/closed-loans'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={ClosedLoans}
          />
          <AdminRoute
            exact
            path='/admin-disbursed'
            privileges={[roles.DISBURSE_LOAN]}
            component={AdminDisbursed}
          />
          <AdminRoute
            exact
            path='/interest-tracker/:loanId'
            privileges={[roles.VIEW_INTEREST_TRACKER]}
            component={InterestTracker}
          />
          <AdminRoute
            exact
            path='/interest-tracker'
            privileges={[roles.VIEW_INTEREST_TRACKER]}
            component={InterestTracker}
          />
          {/* just for testing */}
          <AdminRoute
            exact
            path='/interest-payment-details/:userId'
            privileges={[roles.VIEW_INTEREST_TRACKER]}
            component={InterestPaymentDetails}
          />
          <AdminRoute
            exact
            path='/admin-declined'
            privileges={[roles.APPROVE_REJECTED_LOANS]}
            component={AdminDeclined}
          />
          <AdminRoute
            exact
            path='/agreement-docs'
            privileges={[roles.VIEW_DASHBOARD]}
            component={HtmlAgreement}
          />
          <AdminRoute
            exact
            path='/system-logs'
            privileges={[roles.VIEW_SYSTEM_LOGS]}
            component={SystemLogs}
          />
          <AdminRoute
            exact
            path='/admin-customers'
            privileges={[roles.VIEW_USERS]}
            component={AdminCustomers}
          />
          <AdminRoute
            exact
            path='/admin-customers-details/:id'
            privileges={[roles.VIEW_USERS]}
            component={CustomerNavbar}
          />

          <AdminRoute
            exact
            path='/admin-customers-details-documents/:id'
            privileges={[roles.VIEW_USERS]}
            component={CustomerDocs}
          />
          <AdminRoute
            exact
            path='/admin-create-customer-account/:id'
            privileges={[roles.ALLOW_CREATE_BANK_ACCOUNT]}
            component={CreateAccountNav}
          />
          <AdminRoute
            exact
            path='/admin-transfer-account/:id'
            privileges={[roles.ALLOW_CREATE_BANK_ACCOUNT]}
            component={AccountTransferNav}
          />
          <AdminRoute
            exact
            path='/receipt-page'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={ReceiptPage}
          />
          <AdminRoute
            exact
            path='/finance'
            privileges={[roles.ALLOW_CREATE_BANK_ACCOUNT]}
            component={FinanceNav}
          />
          <AdminRoute
            exact
            path='/admin-customers-module'
            privileges={[roles.VIEW_USERS]}
            component={CustomerModule}
          />
          <AdminRoute
            exact
            path='/admin-saas-customers'
            privileges={[roles.ADD_TERMINAL_ID]}
            component={SaasCustomerTable}
          />
          <AdminRoute exact path='/admin/customer-module/:userId' component={CustomerDocs} />
          <AdminRoute
            exact
            path='/admin-leads'
            privileges={[roles.VIEW_USERS]}
            component={LeadsTable}
          />
          <AdminRoute
            exact
            path='/customer-directors'
            privileges={[roles.VIEW_USERS]}
            component={CustomerDirectors}
          />
          <AdminRoute
            exact
            path='/distributors'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={DistributorsList}
          />

          <CustomerRoutes
            exact
            path='/apply-for-loan/:userId'
            privileges={[roles.APPLY_FOR_LOANS]}
            component={NewApplyForLoan}
          />
          <CustomerRoutes
            exact
            path='/apply-express-loan/:userId'
            privileges={[roles.APPLY_FOR_LOANS]}
            component={ApplyExistingLoan}
          />
          <AdminRoute
            exact
            path='/admin-upload-docs'
            privileges={[roles.APPLY_FOR_LOANS]}
            component={AdminUploadDoc}
          />
          <AdminRoute
            exact
            path='/admin-roles'
            privileges={[roles.VIEW_ROLES]}
            component={AdminRoles}
          />
          <AdminRoute
            exact
            path='/send-reminder/:slug'
            privileges={[roles.VIEW_FEEDBACK]}
            component={Reminder}
          />
          <AdminRoute
            exact
            path='/settings'
            privileges={[roles.MODIFY_ROLES]}
            component={Settings}
          />
          <AdminRoute
            exact
            path='/administration'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={Administration}
          />
          <AdminRoute
            exact
            path='/messages'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={Messages}
          />
          <AdminRoute
            exact
            path='/blacklist'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={BlackList}
          />
          <AdminRoute
            exact
            path='/risk-management'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={RiskManagement}
          />
          <AdminRoute
            exact
            path='/review-details-page'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={ReviewDetailsPage}
          />
          <AdminRoute
            exact
            path='/approved-details-page'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={ApprovedDetailsPage}
          />
          <AdminRoute
            exact
            path='/disbursed-details-page'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={DisbursedDetailsPage}
          />

          <AdminRoute
            exact
            path='/declined-details-page'
            privileges={[roles.VIEW_ADMINISTRATION]}
            component={DeclinedDetailsPage}
          />
          <Route exact path='*' component={notFound.NotFound} />
        </Switch>
        <FooterNew />
      </Suspense>
    </Router>
  )
}
export default Routes
