/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import WarningImg from '../../images/info-circle.svg'

const WarningModal = ({ visible, onNext, onCancel, loading, message }) => {
  return (
    <Modal open={visible} centered footer={null} closable={false} maskClosable={false} width={400}>
      <div className='text-center flex flex-col items-center z-30'>
        <img src={WarningImg} alt='Warning' className='w-24 h-auto' />
        <h1 className='text-3xl font-bold mt-4'>Warning</h1>
        <h2 className='text-2xl px-6 mt-2 mb-6'>{message}</h2>
        <div className='flex gap-6'>
          <button
            onClick={onCancel}
            disabled={loading}
            className='btn-primary md:px-12 py-2 text-gray-500 font-thin bg-white outline border border-gray-300 flex items-center justify-center'
          >
            No
          </button>
          <button
            className='btn-primary md:px-12 py-2 text-gray-500 font-thin bg-white outline border border-gray-300 flex items-center justify-center'
            onClick={onNext}
            disabled={loading}
          >
            {loading ? <LoadingOutlined spin /> : 'Yes'}
          </button>
        </div>
      </div>
    </Modal>
  )
}

WarningModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
}

export default WarningModal
