import { message } from 'antd'

const handleCopyLink = link => {
  navigator.clipboard
    .writeText(link)
    .then(() => {
      message.success('Link copied to clipboard')
    })
    .catch(() => {
      message.error('Oops, unable to copy link')
    })
}

export default handleCopyLink
