import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Table, DatePicker, Pagination, notification } from 'antd'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import { BlacklistTableColumns } from './constants'
import _debounce from 'lodash/debounce'
import ApiHandler from '../../../services/ApiHandler'
import { decryptValueWithPrivateKey } from '../../../utils/helpers'

const BlackList = () => {
  const [blacklistedUsers, setBlacklistedUsers] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const searchInputRef = useRef(null)

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  }

  const fetchBlacklistedUsers = async (params = {}) => {
    setLoading(true)
    try {
      const response = await ApiHandler.get('/admin/blacklist-user', { params })
      setBlacklistedUsers(
        response.data?.data?.map(user => ({
          ...user,
          key: user.id,
          phone_number: user.phone_number
            ? decryptValueWithPrivateKey(user.phone_number) // Decrypt phone_number if it exists
            : null
        })) || []
      )
    } catch (error) {
      console.error('Error fetching blacklisted users:', error)
      notification.error({
        message: 'Error',
        description: error.response?.data?.message || 'Failed to fetch blacklisted users.'
      })
    } finally {
      setLoading(false)
    }
  }
  // Pass fetchBlacklistedUsers to BlacklistTableColumns
  const columns = BlacklistTableColumns(fetchBlacklistedUsers)

  const handleSearch = useCallback(
    _debounce(async ({ target }) => {
      const searchValue = target.value.trim()
      if (searchValue.length < 2) return
      setSearchText(searchValue)
      await fetchBlacklistedUsers({ search: searchValue })
    }, 300),
    []
  )

  const handleSearchDate = async (_, date) => {
    setFilterDateRange(date)
    setCurrentPage(1)
    await fetchBlacklistedUsers({ startDate: date[0], endDate: date[1], page: 1 })
  }

  const handlePageChange = async page => {
    setCurrentPage(page)
    await fetchBlacklistedUsers({ page })
  }

  useEffect(() => {
    fetchBlacklistedUsers()
  }, [currentPage, filterDateRange, searchText]) // Re-fetch data on filters/pagination change

  return (
    <DashboardLayout>
      <div className='m-5'>
        <div className='mb-10'>
          <div className='flex justify-between items-center'>
            <div className='flex'>
              <div className='relative mt-6 rounded-md mr-6 ml-0 col-span-3'>
                <div className='absolute top-4 left-2 pl-2'>
                  <button onClick={() => searchInputRef.current.focus()}>
                    <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />
                  </button>
                </div>
                <input
                  type='text'
                  ref={searchInputRef}
                  onChange={handleSearch}
                  className='h-14 w-full pl-10 border pr-10 rounded-md z-0 focus:shadow focus:outline-none'
                  placeholder='Search for Blacklisted Users'
                />
              </div>
              <div className='flex mt-6'>
                <span className='mt-4'>Filter by:</span>
                <div className='relative flex border rounded-md ml-3'>
                  <DatePicker.RangePicker
                    onChange={handleSearchDate}
                    className='h-14 w-60 pl-2 pr-8 text-center rounded-md z-0 focus:shadow focus:outline-none'
                  />
                </div>
              </div>
            </div>
            <div>
              {' '}
              <button
                className='bg-primary border border-primary hover:bg-white hover:text-primary text-white px-8 py-2 flex w-17 mt-6 mx-auto self-center justify-center'
                onClick={() => fetchBlacklistedUsers()}
              >
                Reset Filters
              </button>
            </div>
          </div>
        </div>

        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={blacklistedUsers}
          loading={loading}
          pagination={false}
        />

        <div className='flex justify-center mt-4'>
          <Pagination
            current={currentPage}
            total={blacklistedUsers.length}
            pageSize={10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </DashboardLayout>
  )
}

export default BlackList
