import IdsComponent from '../../../sharedComponents/others/IdsComponent'
import { BlacklistAction } from './BlacklistAction'

/* eslint-disable camelcase */
export const BlacklistTableColumns = fetchBlacklistedUsers => [
  {
    title: <span className='font-bold'>Customer ID</span>,
    dataIndex: 'id',
    key: 'id',
    render: id => <IdsComponent id={id} />
  },
  {
    title: <span className='font-bold'>Business Name</span>,
    dataIndex: 'business_name',
    key: 'business_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.business_name.localeCompare(b.business_name)
  },
  {
    title: <span className='font-bold'>Admin Name</span>,
    dataIndex: 'name',
    key: 'name',
    defaultSortOrder: ['ascend'],
    render: (_, record) => `${record.first_name} ${record.last_name}`,
    sorter: (a, b) =>
      `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`)
  },
  {
    title: <span className='font-bold'>Email address</span>,
    dataIndex: 'email',
    key: 'email',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.email.localeCompare(b.email)
  },
  {
    title: <span className='font-bold'>Phone Number</span>,
    dataIndex: 'phone_number',
    key: 'phone_number',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.phone_number - b.phone_number
  },
  {
    title: <span className='font-bold'>Customer Documents</span>,
    dataIndex: 'documents',
    key: 'documents',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.documents - b.documents,
    render: documents => (
      <span
        className={
          documents && documents.length > 0
            ? 'bg-green-50 text-green-600 px-2 py-1'
            : 'bg-red-50 text-red-600 px-2 py-1'
        }
      >
        {documents && documents.length > 0 ? 'Available' : 'Not available'}
      </span>
    )
  },
  {
    title: <span className='font-bold capitalize'>Actions</span>,
    datakey: '',
    key: 'action',
    render: record => (
      <BlacklistAction record={record} fetchBlacklistedUsers={fetchBlacklistedUsers} />
    )
  }
]
