import ApiHandler from './ApiHandler'

export default {
  createAdmin: data => ApiHandler.post('admin/add_admin', data),
  makeExistingUserAdmin: data => ApiHandler.post('admin/promote_user', data),
  getExistingUsers: (query, size = 10) =>
    ApiHandler.get(`admin/users?${query}&per_page=${size}`, {}),
  getSimplifiedUsers: () => ApiHandler.get('admin/all-users'),
  addUser: data => ApiHandler.post('admin/add_user', data),
  updateAdmin: data => ApiHandler.post('admin/update_admin_role', data),
  updateUser: data => ApiHandler.put('admin/update-customer-info', data),
  getCustomerBizInfo: userId => ApiHandler.get(`admin/view-customer-business-info/${userId}`),
  getVittasAccountInfo: () => ApiHandler.get('admin/vfd/vittas-account-info'),
  getBanks: () => ApiHandler.get('admin/vfd/banks'),
  initiateTransfer: (data, userId) => ApiHandler.post(`admin/vfd/transfer/${userId}`, data),
  getCustomerVdfAccountInformation: (userId, source) =>
    ApiHandler.get(`admin/user-vdf-accounts/${userId}?source=${source}`),
  getCustomerVfdAccountCreationEligibility: userId =>
    ApiHandler.get(`admin/vfd/get-customer-vfd-account-creation-eligibility/${userId}`),
  getCustomerVfdTransactions: userId => ApiHandler.get(`admin/customer-vfd-transactions/${userId}`),
  getCustomerVfdAccountBalance: userId => {
    if (userId) {
      return ApiHandler.get(`admin/vfd-account-info/${userId}`)
    }
  },
  getVdfLoanAnalytics: source => {
    if (source) {
      return ApiHandler.get(`admin/vdf/loans-analytics?source=${source}`)
    }
  },
  getVdfLoans: (source, active) => {
    return !active
      ? ApiHandler.get(`admin/vdf/loans?source=${source}`)
      : ApiHandler.get(`admin/vdf/loans?source=${source}&active=${active}`)
  },
  getVdfDebtAccounts: (status = 'ALL') =>
    ApiHandler.get(`admin/vdf/list-accounts?status=${status}`),
  createVdfDebtAccount: data => ApiHandler.post('admin/create-vdf-debt-account', data),
  createCustomerVfdAccount: data => ApiHandler.post('admin/create-user-vdf-account', data),
  deactivateAccountPnd: userId => ApiHandler.get(`admin/deactive-account-pnd/${userId}`),
  getCustomerConsent: userId => ApiHandler.get(`admin/get-customer-consent/${userId}`),
  getVfdTransactionByReference: reference => ApiHandler.get(`admin/vfd/transactions/${reference}`),
  updateCustomerBizInfo: data => ApiHandler.put('admin/update-customer-business-info', data),
  getUserInfo: userId => ApiHandler.get(`admin/get-user-info/${userId}`),
  adminSendMessage: (data, messageType) =>
    ApiHandler.post(`admin/send-message/${messageType}`, data),
  customerBvnConsent: data => ApiHandler.post('admin/flick/bvn-consent', data),
  sendBvnConsentLink: (data, consentType) =>
    ApiHandler.post(`admin/flick/send-bvn-consent-link?consent_type=${consentType}`, data),
  bvnAccountLookup: data => ApiHandler.post('admin/flick/bvn-account-lookup', data),
  directDebitInitialize: data => ApiHandler.post('admin/flick/direct-debit-initialize', data),
  getDirectDebitStatus: (userId, transactionId) =>
    ApiHandler.get(
      `admin/flick/direct-debit-status?user_id=${userId}&transaction_id=${transactionId}`
    ),
  userHasDirectDebit: userId => ApiHandler.get(`admin/flick/has-direct-debit?user_id=${userId}`),
  getUserDirectDebits: userId => ApiHandler.get(`admin/flick/user-direct-debits?user_id=${userId}`),
  cancelUserDirectDebits: (userId, transactionId) =>
    ApiHandler.put(
      `admin/flick/cancel-direct-debits?user_id=${userId}&transaction_id=${transactionId}`
    ),
  getDirectDebitHistory: () => ApiHandler.get('admin/direct-debit-history'),
  getSystemLogs: (query, size = 10) => ApiHandler.get(`admin/logs?${query}&per_page=${size}`, {})
}
