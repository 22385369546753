/* eslint-disable react/display-name */
import { Menu } from 'antd'
import React from 'react'
import { getColumnSearchProps } from '../../sharedComponents/antSearch/search'
import AddDirectorToDistributor from './AddDirToDistributorModal'
import DropDown from '../../sharedComponents/drop-down/DropDown'
const CHOOSE = 'Choose a distributors below'
const DISTRIBUTORS = 'All Distributors'
const NEXT = 'Next'
const ADD_DISTRIBUTOR = 'Add Distributor'
const CREATED_AT = 'Created At'
const ADDRESS = 'Address'
const MOU = 'MOU'
const NAME = 'Distributor Name'
const DRAG_FILES = 'Drag files here'
const OR = 'OR'
const EMAIL = 'Email'
const CAC = 'CAC'
const ARTICLES_OF_INCORPORATION = 'Articles of Incorporations'

const distributorsColumn = (setDetails, searchObject) => [
  {
    title: 'Distributor name',
    dataIndex: 'name',
    key: 'name',
    defaultSortOrder: 'ascend',
    width: '10%',
    ...getColumnSearchProps({
      dataIndex: 'name',
      ...searchObject,
      title: 'Distributor Name'
    }),
    sorter: (a, b) => {
      const nameA = a.name?.toUpperCase()
      const nameB = b.name?.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }
  },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  { title: 'Address', dataIndex: 'address', key: 'address' },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at)
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
  },
  // {
  //   title: 'MOU',
  //   dataIndex: 'mou',
  //   key: 'mou',
  //   render: mou => (
  //     <a href={mou} target='_blank' rel='noopener noreferrer'>
  //       view
  //     </a>
  //   )
  // },
  {
    title: 'ACTION',
    key: 'action',
    render: records => (
      <DropDown>
        <Menu>
          <Menu.Item key={1}>
            <button onClick={() => setDetails(records)}>
              <i className='fas fa-pencil-alt mr-2' aria-hidden='true' />
              Edit
            </button>
          </Menu.Item>
          <Menu.Item key={2}>
            <AddDirectorToDistributor distributorRecords={records} />
          </Menu.Item>
          <Menu.Item key={3}>
            <a href={records?.records?.cac} target='_blank' rel='noopener noreferrer'>
              <i className='fa fa-eye mr-2' />
              View CAC
            </a>
          </Menu.Item>
          <Menu.Item key={4}>
            <a href={records?.records?.aoa} target='_blank' rel='noopener noreferrer'>
              <i className='fa fa-eye mr-2' />
              View AOA
            </a>
          </Menu.Item>
          <Menu.Item key={5}>
            <a href={records?.mou} target='_blank' rel='noopener noreferrer'>
              <i className='fa fa-eye mr-2' />
              View MOU
            </a>
          </Menu.Item>
        </Menu>
      </DropDown>
    )
  }
]

export {
  DISTRIBUTORS,
  CHOOSE,
  ARTICLES_OF_INCORPORATION,
  CAC,
  NEXT,
  ADD_DISTRIBUTOR,
  CREATED_AT,
  ADDRESS,
  EMAIL,
  MOU,
  OR,
  DRAG_FILES,
  NAME,
  distributorsColumn
}
