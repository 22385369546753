import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { directDebitColumns } from './constants'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { adminServices } from '../../../services'

const DirectDebitTransactions = () => {
  const [directDebits, setDirectDebits] = useState([])
  const userIdFromParams = useParams().id
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetch() {
      if (userIdFromParams) {
        const response = await adminServices.getUserDirectDebits(userIdFromParams).finally(() => {
          setLoading(false)
        })
        setDirectDebits(response?.data?.data)
      }
    }

    fetch()
  }, [userIdFromParams])

  return (
    <div className='m-5 h-screen'>
      <Table
        rowKey='transactionId'
        className='mt-5'
        columns={loading ? [] : directDebitColumns()}
        dataSource={directDebits}
        loading={loading}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, hideOnSinglePage: true }}
      />
    </div>
  )
}

export default DirectDebitTransactions
