import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { loanAppColumns } from './constants'
import { useSelector } from 'react-redux'
import { dispatch, select } from '../../../redux/store'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import LoanTypeModal from '../applyLoanForExistingCustomer/LoanTypeModal'

const CustomerLoanApp = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const userLoan = useSelector(select.loans.getUserLoansById)
  const [modalVisible, setModalVisible] = useState(false)
  const [record, setRecord] = useState(false)
  const loadingUserLoans = useSelector(({ loading }) => loading.effects.loans.getUserLoans)
  const userIdFromParams = useParams().id
  const { permissions } = useSelector(({ auth }) => auth.authUser)

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const getAllUserLoans = async (refresh = true) => {
    await dispatch.loans.getUserLoans({ record: { id: userIdFromParams }, refresh })
  }

  useEffect(() => {
    getAllUserLoans(false)
  }, [])

  return (
    <div className='m-5 h-screen'>
      <LoanTypeModal
        modalVisible={modalVisible}
        previewData={{
          loanAmount: record.loan_amount,
          loanDuration: record.duration_of_loan,
          loanType: record.loan_type,
          startDate: record?.created_at || record?.approval_date || record?.time_submitted
        }}
        setModalVisible={setModalVisible}
      />
      <Table
        className='mt-5'
        rowSelection={rowSelection}
        columns={loanAppColumns(permissions, { setModalVisible, setRecord, getAllUserLoans })}
        dataSource={userLoan?.map(item => ({
          ...item,
          key: item.loan_id,
          loan_amount: item.is_approved
            ? item.approved_obj.loan_principal_amount_fixed
            : item.loan_amount
        }))}
        loading={loadingUserLoans}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, hideOnSinglePage: true }}
      />
    </div>
  )
}
export default CustomerLoanApp
