/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Table, DatePicker, Pagination, notification } from 'antd'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import CheckBvn from '../../../sharedComponents/others/CheckBvn'
import { customerModuleColumns } from './constants'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../redux/store'
import { capitalizedWord, decryptValueWithPrivateKey } from '../../../utils/helpers'
import _debounce from 'lodash/debounce'
import ApiHandler from '../../../services/ApiHandler'

const CustomerModule = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const searchInputRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const { usersList, userLoading, pagination } = useSelector(({ loading, users }) => ({
    userLoading: loading.effects.users.getAllusers,
    usersList:
      users?.paginatedUsers?.map(
        ({
          first_name,
          last_name,
          documents,
          business_name,
          bvn,
          phone_number,
          nin,
          id,
          ...more
        }) => ({
          name: capitalizedWord(first_name + ' ' + last_name),
          documents,
          first_name,
          last_name,
          key: id,
          id,
          bvn: decryptValueWithPrivateKey(bvn),
          nin: decryptValueWithPrivateKey(nin),
          phone_number: decryptValueWithPrivateKey(phone_number),
          business_name: capitalizedWord(business_name) || 'N/A',
          ...more
        })
      ) || [],
    pagination: users?.pagination
  }))

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const handleAddToBlacklist = async () => {
    setLoading(true)
    try {
      const endpoint = '/admin/blacklist-user'

      // Map selected users to API payloads
      const blacklistPromises = selectedRowKeys.map(userId =>
        ApiHandler.post(endpoint, {
          user_id: userId,
          blacklist_status: 1 // Add to blacklist
        })
      )

      // Execute all blacklist requests
      await Promise.all(blacklistPromises)

      notification.success({
        message: 'Success',
        description: `${selectedRowKeys.length} user(s) added to blacklist successfully.`
      })

      setSelectedRowKeys([]) // Clear selected rows
      await dispatch.users.getAllusers({
        shouldCache: false
      })
    } catch (error) {
      console.error('Error adding users to blacklist:', error)
      notification.error({
        message: 'Error',
        description: error.response?.data?.message || 'An error occurred while blacklisting users.'
      })
    } finally {
      setLoading(false)
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const handleSearchDate = async (_, date) => {
    if (!date || !_) {
      return resetFilters()
    }
    setFilterDateRange(date)
    setCurrentPage(1)
    await fetchUsers({ startDate: date[0], endDate: date[1], page: 1 })
  }

  const handleSearch = useCallback(
    _debounce(async ({ target }) => {
      const searchValue = target.value.trim()

      if (!searchValue || searchValue.length < 2) return

      setSearchText(searchValue)

      await dispatch.users.getAllusers({
        search: searchValue
      })
    }, 300),
    []
  )

  const fetchUsers = async (params = {}) => {
    await dispatch.users.getAllusers({
      page: params.page || currentPage,
      startDate: filterDateRange ? filterDateRange[0] : undefined,
      endDate: filterDateRange ? filterDateRange[1] : undefined,
      search: searchText || undefined,
      ...params
    })
  }

  const handlePageChange = async page => {
    setCurrentPage(page)
    await fetchUsers({ page })
  }

  const resetFilters = async () => {
    setSearchText('')
    setFilterDateRange(null)
    setCurrentPage(1)
    await fetchUsers({ page: 1 })
  }

  useEffect(() => {
    fetchUsers()
  }, []) // Only run on mount

  return (
    <DashboardLayout>
      <div className='m-5'>
        <div className='mb-10'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 text-lg'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 text-lg'>
              <div className='relative mt-6 rounded-md mr-6 ml-0 col-span-3'>
                <div className='absolute top-4 left-2 pl-2'>
                  <button onClick={() => searchInputRef.current.focus()}>
                    <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />
                  </button>
                </div>
                <div>
                  <input
                    type='text'
                    ref={searchInputRef}
                    onChange={handleSearch}
                    className='h-14 w-full pl-10 border pr-10 rounded-md z-0 focus:shadow focus:outline-none'
                    placeholder='Search ID, Business name...'
                  />
                </div>
              </div>
            </div>
            <div className='flex mt-6'>
              <span className='mt-4'>Filter by:</span>
              <div className='relative flex border rounded-md ml-3'>
                <DatePicker.RangePicker
                  onChange={handleSearchDate}
                  className='h-14 w-60 pl-2 pr-8 text-center rounded-md z-0 focus:shadow focus:outline-none'
                />
              </div>
            </div>
            <button
              className='btn-primary border-primary border hover:bg-primary text-primary px-8 py-2 flex w-17 mt-6 mx-auto self-center justify-center hover:text-white'
              onClick={resetFilters}
            >
              Reset
            </button>
            <CheckBvn />
          </div>
        </div>

        {selectedRowKeys.length > 0 && (
          <div className='text-primary border border-primary btn-primary mt-10 px-8 py-3 rounded z-10 mx-80 text-lg text-center'>
            {`${selectedRowKeys.length} Customer${
              selectedRowKeys.length !== 1 ? 's' : ''
            } Selected`}

            <span onClick={handleAddToBlacklist} className='text-red-600 pl-3 cursor-pointer'>
              {' '}
              {loading ? 'Adding...' : 'Add to Blacklist'}
            </span>
          </div>
        )}

        <Table
          rowSelection={rowSelection}
          columns={customerModuleColumns}
          dataSource={usersList}
          loading={userLoading || loading}
          pagination={false} // We handle pagination manually
        />

        <div className='flex justify-center mt-4'>
          <Pagination
            current={currentPage || 0}
            total={pagination?.total_items || 0}
            pageSize={pagination?.page_size || 10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </DashboardLayout>
  )
}

export default CustomerModule
