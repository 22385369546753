import { notification, Popconfirm } from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useState } from 'react'
import DropDown from '../../../sharedComponents/drop-down/DropDown'
import ApiHandler from '../../../services/ApiHandler'

export const BlacklistAction = ({ record, permissions, fetchBlacklistedUsers }) => {
  const [loading, setLoading] = useState(false)
  const handleRestoreBlacklistedUser = async () => {
    setLoading(true)
    try {
      const endpoint = '/admin/blacklist-user'
      await ApiHandler.post(endpoint, {
        user_id: record.id,
        blacklist_status: 0
      })
      setLoading(false)

      notification.success({
        message: 'Success',
        description: 'User removed from blacklist successfully.'
      })

      await fetchBlacklistedUsers()
    } catch (error) {
      console.error('Error removing user from blacklist:', error)
      notification.error({
        message: 'Error',
        description: error.response?.data?.message || 'An error occurred while restoring the user.'
      })
    } finally {
      setLoading(false)
    }
  }
  return (
    <DropDown>
      <Link
        to={`/admin-customers-details/${record.id}`}
        style={{ outline: 0 }}
        className='w-full text-left nav-sm-link'
      >
        <i className='far fa-eye mr-2' /> View Details
      </Link>
      <Popconfirm
        title='Restore this user from the blacklist?'
        onConfirm={handleRestoreBlacklistedUser}
        okText='Yes'
        cancelText='No'
      >
        <button
          style={{ outline: 0 }}
          className='w-full hover:text-blue-500 ease-in-out text-left nav-sm-link'
        >
          <i className='fas fa-undo mr-2' /> {loading ? 'Restoring...' : 'Restore'}
        </button>
      </Popconfirm>
    </DropDown>
  )
}

BlacklistAction.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    is_disabled: PropTypes.bool.isRequired
  }).isRequired,
  permissions: PropTypes.object,
  fetchBlacklistedUsers: PropTypes.func.isRequired
}
