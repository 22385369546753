import { Link } from 'react-router-dom'

function DirectDebitSuccessPage() {
  return (
    <div className='min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4'>
      <div className='max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center'>
        {/* Success Icon */}
        <div className='mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            className='h-10 w-10 text-green-500'
          >
            <path
              fillRule='evenodd'
              d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
              clipRule='evenodd'
            />
          </svg>
        </div>

        {/* Success Message */}
        <h1 className='text-2xl font-bold text-gray-900 mb-3'>
          Direct Debit Consent Granted Successfully!
        </h1>

        <p className='text-gray-600 mb-8'>
          Thank you for granting consent. Your Direct Debit verification process has been completed
          successfully.
        </p>

        {/* Illustration */}
        <div className='mb-8'>
          <img
            src='/images/success-illustration.svg'
            alt='Success Illustration'
            className='w-64 h-64 mx-auto'
          />
        </div>

        {/* Action Button */}
        <Link
          to='/'
          className='inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200'
        >
          Return to Homepage
        </Link>
      </div>
    </div>
  )
}

export default DirectDebitSuccessPage
